import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Plank 3×1:15 & Superman Extension 3×10/side `}<em parentName="p">{`(alternate arm/leg
raise)`}</em></p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1 Clean Grip Deadlift`}</p>
    <p>{`:05 Pause below knee to Clean Grip Deadlift`}</p>
    <p>{`1 Jump Shrug Pull from above knee`}</p>
    <p>{`*`}{`start with 80% 1RM Clean and add weight each round if possible`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`12-KBS’s (53/35)`}</p>
    <p>{`10-Box Jump Overs (24/20)`}</p>
    <p><em parentName="p">{`*`}{`*`}{`compare to 2/16/20`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      